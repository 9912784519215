<template>
    <div class="events-layout">
        <topWelcomeBanner style="position: relative; top: 60px; z-index: 1;"></topWelcomeBanner>
        <slot></slot>
    </div>
</template>

<script>
import topWelcomeBanner from "@/components/topWelcomeBanner.vue";
export default {
    name: "eventsLayout",
    components: {
        topWelcomeBanner
    }
}
</script>